export function defaultColor() {

  let defaultColor = localStorage.getItem('defaultColor');
  let bodyStyles = document.body.style;

  switch (defaultColor) {
    case 'default':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#53906F')
      bodyStyles.setProperty('--primary', '#2E6C4A')
      bodyStyles.setProperty('--primaryDark', '#0D2E1D')
      break;
    case 'dark':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#969696')
      bodyStyles.setProperty('--primary', '#464646')
      bodyStyles.setProperty('--primaryDark', '#000000')
      break;
    case 'light':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#BBBBBB')
      bodyStyles.setProperty('--primary', '#999999')
      bodyStyles.setProperty('--primaryDark', '#8D8D8D')
      break;
    case 'blue':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#625CB3')
      bodyStyles.setProperty('--primary', '#362F93')
      bodyStyles.setProperty('--primaryDark', '#292466')
      break;
    case 'pink':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#FAE1CB')
      bodyStyles.setProperty('--primary', '#EDB5A8')
      bodyStyles.setProperty('--primaryDark', '#E38F9E')
      break;
    case 'sunshine':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#F1C095')
      bodyStyles.setProperty('--primary', '#E9B562')
      bodyStyles.setProperty('--primaryDark', '#C08752')
      break;
    case 'older':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#BD917C')
      bodyStyles.setProperty('--primary', '#966D58')
      bodyStyles.setProperty('--primaryDark', '#74503E')
      break;
    case 'hot':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#F8890A')
      bodyStyles.setProperty('--primary', '#E45151')
      bodyStyles.setProperty('--primaryDark', '#632F8D')
      break;
    case 'red':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#FA7070')
      bodyStyles.setProperty('--primary', '#FC4A4A')
      bodyStyles.setProperty('--primaryDark', '#C00000')
      break;
    case 'gold':
      //~> Cores Primárias
      bodyStyles.setProperty('--primaryLight', '#747272')
      bodyStyles.setProperty('--primary', '#A77D00')
      bodyStyles.setProperty('--primaryDark', '#404040')
      break;
  
    default:
      break;
  }

}
